import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';

export const UNSUBSCRIBE_REQUEST = 'unsubscribe/REQUEST';
export const UNSUBSCRIBE_SUCCESS = 'unsubscribe/SUCCESS';
export const UNSUBSCRIBE_FAILURE = 'unsubscribe/FAILURE';

export const unsubscribeRequest = createAction(UNSUBSCRIBE_REQUEST);
export const unsubscribeSuccess = createAction(UNSUBSCRIBE_SUCCESS);
export const unsubscribeFailure = createAction(UNSUBSCRIBE_FAILURE);

export function unsubscribe(entity = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(unsubscribeRequest(entity));

    const promise = request.post(`/_api/subscriptions/unsubscribe`, entity);

    return promise
      .then(() => dispatch(unsubscribeSuccess(entity)))
      .catch(() => dispatch(unsubscribeFailure(entity)))
      .then(() => promise);
  };
}

export const unsubscribePromisified = createPromisifiedAction(unsubscribe, data => data, response => response.status);
