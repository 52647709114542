import { FETCH_USER_SUCCESS } from '../../actions/fetch-user';
import { USER_JOIN_COMMUNITY_SUCCESS } from '../../actions/join-community';
import { USER_PRIVACY_STATUS_PUBLIC } from '../../constants/user-privacy-status';

export function currentUser(state = null, { type, payload }) {
  switch (type) {
    case FETCH_USER_SUCCESS:
      return payload;
    case USER_JOIN_COMMUNITY_SUCCESS:
      return { ...payload, privacyStatus: USER_PRIVACY_STATUS_PUBLIC };
    default:
      return state;
  }
}
