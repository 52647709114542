import { trim } from 'lodash';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';
import { setIsLoading } from '../store/is-loading/is-loading-actions';
import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import createPermissionChecker from '../services/create-permission-checker';
import createPermissionHelpers from '../services/create-permission-helpers';
import { extractHashtagsFromContent } from '../services/hashtag-utils';
import { discardDeletedCategoryIds, normalizePost } from '../services/post-utils';
import { getCategoryIds } from '../selectors/categories-selectors';
import { getCurrentUser } from '../store/current-user/current-user-selectors';
import { showLoading, hideLoading } from '../store/loading-bar/loading-bar-actions';

export const SAVE_POST_DRAFT_REQUEST = 'post/SAVE_DRAFT_REQUEST';
export const SAVE_POST_DRAFT_SUCCESS = 'post/SAVE_DRAFT_SUCCESS';
export const SAVE_POST_DRAFT_FAILURE = 'post/SAVE_DRAFT_FAILURE';
export const STOP_LOADING_DEBOUNCED = 'post/STOP_LOADING_DEBOUNCED';

export const savePostDraftRequest = createAction(SAVE_POST_DRAFT_REQUEST);
export const savePostDraftSuccess = createAction(SAVE_POST_DRAFT_SUCCESS);
export const savePostDraftFailure = createAction(SAVE_POST_DRAFT_FAILURE);

const TIMEOUT = 1000;
const ENTITY = 'postDraft';
export const startLoading = ({ draftKey }) => dispatch => dispatch(setIsLoading(ENTITY, draftKey, true));
export const stopLoading = ({ draftKey }) => dispatch => dispatch(setIsLoading(ENTITY, draftKey, false));
export const stopLoadingDebounced = ({ draftKey }) => dispatch => {
  const action = dispatch => {
    dispatch(stopLoading({ draftKey }));
    dispatch(hideLoading());
  };
  action.type = STOP_LOADING_DEBOUNCED;
  action.meta = { debounce: TIMEOUT };

  return dispatch(action);
};

export const savePostDraft = ({
  _id,
  title,
  canonicalUrl,
  content,
  excerpt,
  categoryIds,
  coverImage,
  firstPublishedDate,
  seoSlug,
  seoTitle,
  seoDescription,
  isFeatured,
  ownerId,
  draftKey,
} = {}) => (dispatch, getState, { request }) => {
  dispatch(savePostDraftRequest({ draftKey }));
  dispatch(showLoading());

  const { can } = createPermissionHelpers(createPermissionChecker(getState(), getCurrentUser(getState())));

  const isCreated = Boolean(_id);
  const promise = request(`/_api/posts/${isCreated ? _id : ''}`, {
    method: isCreated ? 'PATCH' : 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      draft: {
        title: trim(title).length ? title : 'Untitled',
        canonicalUrl,
        content,
        excerpt,
        categoryIds: discardDeletedCategoryIds(categoryIds, getCategoryIds(getState())),
        coverImage,
        hashtags: extractHashtagsFromContent(content),
        firstPublishedDate,
        seoSlug,
        seoTitle,
        seoDescription,
        ...(can('toggle-featured', 'post') && { isFeatured }),
        ...(can('change-owner', 'post') && { ownerId }),
      },
    }),
  });

  promise
    .then(post =>
      dispatch(
        savePostDraftSuccess({
          post: normalizePost({ post, blogCategoryIds: getCategoryIds(getState()) }),
          draftKey,
        }),
      ),
    )
    .catch(error => dispatch(savePostDraftFailure(error)))
    .then(() => dispatch(stopLoadingDebounced({ draftKey })));

  return promise;
};

export const savePostDraftPromisified = createPromisifiedAction(
  savePostDraft,
  data => data,
  response => response.status,
);
