import { reduce } from 'lodash';
import { ADD_USER } from '../../actions/add-user';
import { FETCH_USER_SUCCESS } from '../../actions/fetch-user';
import { FETCH_MEMBERS_SUCCESS } from '../../actions/fetch-members';
import { CREATE_USER_SUCCESS } from '../../actions/create-user';
import { UPDATE_USER_SUCCESS } from '../../actions/update-user';
import { BLOCK_USER_SUCCESS } from '../../actions/block-user';
import { UNBLOCK_USER_SUCCESS } from '../../actions/unblock-user';
import { SUBSCRIBE_FAILURE, SUBSCRIBE_REQUEST } from '../../actions/subscribe';
import { UNSUBSCRIBE_FAILURE, UNSUBSCRIBE_REQUEST } from '../../actions/unsubscribe';
import { PROMOTE_WRITER_SUCCESS } from '../../actions/promote-writer';
import { DEMOTE_WRITER_SUCCESS } from '../../actions/demote-writer';
import { USER_SET_PERMISSIONS } from '../../actions/user';

// eslint-disable-next-line complexity
const users = (state = {}, { payload, type } = {}) => {
  switch (type) {
    case ADD_USER:
    case FETCH_USER_SUCCESS:
    case CREATE_USER_SUCCESS:
    case UPDATE_USER_SUCCESS:
    case BLOCK_USER_SUCCESS:
    case UNBLOCK_USER_SUCCESS:
    case PROMOTE_WRITER_SUCCESS:
    case DEMOTE_WRITER_SUCCESS:
    case USER_SET_PERMISSIONS:
      return addUsers(state, [payload]);
    case FETCH_MEMBERS_SUCCESS:
      return addUsers(state, payload);
    case SUBSCRIBE_REQUEST:
      if (payload.type === 'user' && state[payload._id]) {
        return {
          ...state,
          [payload._id]: {
            ...state[payload._id],
            followerCount: state[payload._id].followerCount + 1,
            isSubscribed: true,
          },
        };
      }
      return state;
    case SUBSCRIBE_FAILURE:
      if (payload.type === 'user' && state[payload._id]) {
        return {
          ...state,
          [payload._id]: {
            ...state[payload._id],
            followerCount: state[payload._id].followerCount - 1,
            isSubscribed: false,
          },
        };
      }
      return state;
    case UNSUBSCRIBE_REQUEST:
      if (payload.type === 'user' && state[payload._id]) {
        return {
          ...state,
          [payload._id]: {
            ...state[payload._id],
            followerCount: state[payload._id].followerCount - 1,
            isSubscribed: false,
          },
        };
      }
      return state;
    case UNSUBSCRIBE_FAILURE:
      if (payload.type === 'user' && state[payload._id]) {
        return {
          ...state,
          [payload._id]: {
            ...state[payload._id],
            followerCount: state[payload._id].followerCount + 1,
            isSubscribed: true,
          },
        };
      }
      return state;
    default:
      return state;
  }
};

function addUsers(state, users) {
  return reduce(
    users,
    (acc, user) => ({
      ...acc,
      [user._id]: {
        ...acc[user._id],
        ...user,
      },
    }),
    { ...state },
  );
}

export default users;
