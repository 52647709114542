export const UNBLOCK_USER_REQUEST = 'user/UNBLOCK_REQUEST';
export const UNBLOCK_USER_SUCCESS = 'user/UNBLOCK_SUCCESS';
export const UNBLOCK_USER_FAILURE = 'user/UNBLOCK_FAILURE';

export const unblockUserRequest = () => ({ type: UNBLOCK_USER_REQUEST });
export const unblockUserSuccess = payload => ({ type: UNBLOCK_USER_SUCCESS, payload });
export const unblockUserFailure = () => ({ type: UNBLOCK_USER_FAILURE });

export const unblockUser = userId => (dispatch, getState, { request }) => {
  dispatch(unblockUserRequest());
  const promise = request.post(`/_api/users/unblock/${userId}`);
  return request
    .post(`/_api/users/unblock/${userId}`)
    .then(user => dispatch(unblockUserSuccess(user)))
    .catch(() => dispatch(unblockUserFailure()))
    .then(() => promise);
};
