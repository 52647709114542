import { get } from 'lodash';
import uri from 'urijs';
import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { getMetaSiteId } from '../instance-values/instance-values-selectors';

export const FETCH_EXPERIMENTS_REQUEST = 'experiments/FETCH_EXPERIMENTS_REQUEST';
export const FETCH_EXPERIMENTS_SUCCESS = 'experiments/FETCH_EXPERIMENTS_SUCCESS';
export const FETCH_EXPERIMENTS_FAILURE = 'experiments/FETCH_EXPERIMENTS_FAILURE';

export const fetchExperimentsRequest = createAction(FETCH_EXPERIMENTS_REQUEST);
export const fetchExperimentsSuccess = createAction(FETCH_EXPERIMENTS_SUCCESS);
export const fetchExperimentsFailure = createAction(FETCH_EXPERIMENTS_FAILURE);

const EXPERIMENTS_SCOPE = 'wix-social-blog';

export function fetchExperiments() {
  return (dispatch, getState, { experimentsRequest }) => {
    dispatch(fetchExperimentsRequest());

    const promise = experimentsRequest(
      uri('/v1/laboratory/platform/conductAllInScope').query({
        scope: EXPERIMENTS_SCOPE,
        'requestContext.overrideCriteria.entityId': getMetaSiteId(getState()),
      }),
    );

    return promise
      .then(
        body => dispatch(fetchExperimentsSuccess(get(body, 'values', {}))),
        () => dispatch(fetchExperimentsFailure()),
      )
      .then(() => promise);
  };
}
