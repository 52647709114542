import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { setUserPermissions } from './user';

export const PROMOTE_WRITER_REQUEST = 'user/PROMOTE_WRITER_REQUEST';
export const PROMOTE_WRITER_SUCCESS = 'user/PROMOTE_WRITER_SUCCESS';
export const PROMOTE_WRITER_FAILURE = 'user/PROMOTE_WRITER_FAILURE';

export const promoteWriterRequest = createAction(PROMOTE_WRITER_REQUEST);
export const promoteWriterSuccess = createAction(PROMOTE_WRITER_SUCCESS);
export const promoteWriterFailure = createAction(PROMOTE_WRITER_FAILURE);

export const promoteWriter = userId => (dispatch, getState, { request }) => {
  dispatch(promoteWriterRequest());
  const promise = request.patch(`/_api/users/${userId}/permissions`, {
    role: 'writer',
  });
  return promise
    .then(user => {
      dispatch(promoteWriterSuccess(user));
      dispatch(setUserPermissions(user));
    })
    .catch(() => dispatch(promoteWriterFailure()))
    .then(() => promise);
};
