export const BLOCK_USER_REQUEST = 'user/BLOCK_REQUEST';
export const BLOCK_USER_SUCCESS = 'user/BLOCK_SUCCESS';
export const BLOCK_USER_FAILURE = 'user/BLOCK_FAILURE';

export const blockUserRequest = () => ({ type: BLOCK_USER_REQUEST });
export const blockUserSuccess = payload => ({ type: BLOCK_USER_SUCCESS, payload });
export const blockUserFailure = () => ({ type: BLOCK_USER_FAILURE });

export const blockUser = userId => (dispatch, getState, { request }) => {
  dispatch(blockUserRequest());
  const promise = request.post(`/_api/users/block/${userId}`);
  return promise
    .then(user => dispatch(blockUserSuccess(user)))
    .catch(() => dispatch(blockUserFailure()))
    .then(() => promise);
};
