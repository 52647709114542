import { CREATE_USER_SUCCESS } from '../../../actions/create-user';
import { UPDATE_USER_SUCCESS } from '../../../actions/update-user';
import { SET_USER } from '../../../actions/set-user';
import { USER_SET_PERMISSIONS } from '../../../actions/user';
import { LOGOUT_MEMBER_SUCCESS } from '../../../actions/logout-member';
import { SET_AUTH } from '../../../actions/set-auth';
import { UPDATE_USER_PRIVACY_STATUS } from '../../../actions/update-user-privacy-status';
import { FETCH_CURRENT_USER_SUCCESS } from '../../../actions/fetch-current-user';

export default (state = null, { type, payload = {} } = {}) => {
  switch (type) {
    case SET_USER:
    case CREATE_USER_SUCCESS:
    case FETCH_CURRENT_USER_SUCCESS:
      return { ...(state || {}), ...payload };
    case UPDATE_USER_SUCCESS:
    case USER_SET_PERMISSIONS:
      return state && state._id === payload._id
        ? {
            ...state,
            ...payload,
          }
        : state;
    case LOGOUT_MEMBER_SUCCESS:
      return null;
    case SET_AUTH:
      return {
        ...state,
        ...payload.currentUser,
      };
    case UPDATE_USER_PRIVACY_STATUS:
      return {
        ...state,
        privacyStatus: payload,
      };
    default:
      return state;
  }
};
