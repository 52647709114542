import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';

export const FETCH_USER_STATS_REQUEST = 'userStats/FETCH_REQUEST';
export const FETCH_USER_STATS_SUCCESS = 'userStats/FETCH_SUCCESS';
export const FETCH_USER_STATS_FAILURE = 'userStats/FETCH_FAILURE';

export const fetchUserStatsRequest = createAction(FETCH_USER_STATS_REQUEST);
export const fetchUserStatsSuccess = createAction(FETCH_USER_STATS_SUCCESS);
export const fetchUserStatsFailure = createAction(FETCH_USER_STATS_FAILURE);

export const USER_STATS_PATH = '/_api/users/current/numbers';

export const fetchUserStats = () => (dispatch, getState, { request }) => {
  dispatch(fetchUserStatsRequest());

  const promise = request(USER_STATS_PATH);
  return promise
    .then(numbers => dispatch(fetchUserStatsSuccess(numbers)), () => dispatch(fetchUserStatsFailure()))
    .then(() => promise);
};
