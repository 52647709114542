import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';

export const SUBSCRIBE_REQUEST = 'subscribe/REQUEST';
export const SUBSCRIBE_SUCCESS = 'subscribe/SUCCESS';
export const SUBSCRIBE_FAILURE = 'subscribe/FAILURE';

export const subscribeRequest = createAction(SUBSCRIBE_REQUEST);
export const subscribeSuccess = createAction(SUBSCRIBE_SUCCESS);
export const subscribeFailure = createAction(SUBSCRIBE_FAILURE);

export function subscribe(entity = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(subscribeRequest(entity));

    const promise = request.post(`/_api/subscriptions/subscribe`, entity);

    return promise
      .then(() => dispatch(subscribeSuccess(entity)))
      .catch(() => dispatch(subscribeFailure(entity)))
      .then(() => promise);
  };
}

export const subscribePromisified = createPromisifiedAction(subscribe, data => data, response => response.status);
