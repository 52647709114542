import _ from 'lodash';
import { FETCH_COMMENTS_REQUEST, FETCH_COMMENTS_SUCCESS } from './fetch-comments';
import { FETCH_COMMENT_SUCCESS } from './fetch-comment';
import { CREATE_COMMENT_SUCCESS } from './create-comment';
import { UPDATE_COMMENT_SUCCESS } from './update-comment';
import { DELETE_COMMENT_SUCCESS } from './delete-comment';
import { UPDATE_USER_SUCCESS } from '../../actions/update-user';

const comment = (state, action) => {
  switch (action.type) {
    case UPDATE_USER_SUCCESS:
      if (state.owner._id === action.payload.id) {
        return { ...state, owner: action.payload };
      }
      return state;
    default:
      return state;
  }
};

const commentList = (state = [], { type, payload = {} } = {}) => {
  switch (type) {
    case FETCH_COMMENTS_SUCCESS:
      return _([...state, ...payload.comments])
        .unionBy('_id')
        .sortBy('createdDate')
        .value();
    case CREATE_COMMENT_SUCCESS:
    case UPDATE_COMMENT_SUCCESS:
      return _([payload])
        .unionBy(state, '_id')
        .sortBy('createdDate')
        .value();
    case FETCH_COMMENT_SUCCESS:
      return _([payload.comment])
        .unionBy(state, '_id')
        .sortBy('createdDate')
        .value();
    case DELETE_COMMENT_SUCCESS:
      return state.filter(comment => comment._id !== payload._id);
    case UPDATE_USER_SUCCESS:
      return state.map(current => comment(current, { type, payload }));
    default:
      return state;
  }
};

export default function commentsReducer(state = {}, action) {
  const { type, payload = {} } = action || {};
  const { postId } = payload || {};

  switch (type) {
    case FETCH_COMMENTS_REQUEST:
      return {
        [postId]: state[postId],
      };
    case FETCH_COMMENTS_SUCCESS:
    case FETCH_COMMENT_SUCCESS:
    case CREATE_COMMENT_SUCCESS:
    case UPDATE_COMMENT_SUCCESS:
    case DELETE_COMMENT_SUCCESS:
      return {
        [postId]: commentList(state[postId], action),
      };
    default:
      return state;
  }
}
