import { get } from 'lodash';
import { pageOpened } from '../actions/page-opened';
import { isSSR } from '../store/basic-params/basic-params-selectors';

export const createNotFoundPageRouter = (store, page, route) => ({ pathname, prevMatches }) => {
  const isManualRedirect = pathname === route && get(prevMatches, 'length');
  isManualRedirect && (pathname = get(prevMatches, '[0].pathname'));

  !isSSR(store.getState()) && store.dispatch(pageOpened({ page, pathname }));
};
