import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { BI_UOU_ENDPOINT, BI_UOU_SRC } from '@wix/communities-blog-client-common/dist/src/constants/bi-uou-events';
import {
  BI_USERS_ENDPOINT,
  BI_USERS_SRC,
} from '@wix/communities-blog-client-common/dist/src/constants/bi-users-events';
import createBiMiddleware from '../middleware/bi-middleware';
import { getRouteName } from '../services/detect-route';
import {
  getCurrentUserId,
  getCurrentUserRole,
  getCurrentUserSiteMemberId,
} from '@wix/communities-blog-client-common/dist/src/selectors/current-user-selectors';
import { isSite } from '../selectors/view-mode-selectors';
import { getBiToken, getInstanceValue } from '../store/instance-values/instance-values-selectors';
import { getPreviousMatch, getCurrentMatch } from '../router/router-selectors';

const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

const hasBiToken = event => (event.biToken && event.biToken !== EMPTY_GUID) || event.msid;

export const getEventOrigin = ({ state, event }) => {
  const routeMatch = event.eventMeta.isNavigation ? getPreviousMatch(state) : getCurrentMatch(state);
  return getRouteName(routeMatch.route);
};

const extendAppUouEvent = eventDefaults => (rawEvent, state) => {
  const event = { ...eventDefaults, ...rawEvent };
  return {
    biToken: getBiToken(state),
    origin: event.origin || getEventOrigin({ event, state }),
    role: getCurrentUserRole(state),
    member_id: getCurrentUserId(state),
    site_member_id: getCurrentUserSiteMemberId(state),
    visitor_id: getInstanceValue(state, 'aid') || getInstanceValue(state, 'uid'),
    ...event,
  };
};

const extendAppUsersEvent = eventDefaults => (rawEvent, state) => {
  const event = { ...eventDefaults, ...rawEvent };

  return {
    biToken: getBiToken(state),
    role: 'owner',
    origin: event.origin,
    ...event,
  };
};

const createBiInstance = biLoggerFactory => {
  const bi = biLoggerFactory();
  return bi.factory ? bi.factory() : bi;
};

export const appUouBiMiddleware = ({ platformAPIs, instanceId, eventMap, eventDefaults = {} }) => {
  const bi = createBiInstance(platformAPIs.biLoggerFactory);
  bi.updateDefaults({
    src: BI_UOU_SRC,
    app_id: BLOG_APP_ID,
    app_site_id: instanceId,
    instance_id: instanceId,
    referral_info: undefined,
  });

  return createBiMiddleware({
    bi: bi.logger({ endpoint: BI_UOU_ENDPOINT }),
    eventMap,
    extendEvent: extendAppUouEvent(eventDefaults),
    shouldInvokeEvent: (_, state) => isSite(state),
    shouldLogEvent: hasBiToken,
  });
};

export const appUsersBiMiddleware = ({
  platformAPIs,
  instanceId,
  eventMap,
  eventDefaults = {},
  shouldInvokeEvent = (_, state) => !isSite(state),
}) => {
  const bi = createBiInstance(platformAPIs.biLoggerFactory);
  bi.updateDefaults({
    src: BI_USERS_SRC,
    app_id: BLOG_APP_ID,
    community_id: undefined,
    instance_id: instanceId,
    original_instance_id: undefined,
    referral_info: undefined,
  });

  return createBiMiddleware({
    bi: bi.logger({ endpoint: BI_USERS_ENDPOINT }),
    eventMap,
    extendEvent: extendAppUsersEvent(eventDefaults),
    shouldInvokeEvent,
    shouldLogEvent: hasBiToken,
  });
};
