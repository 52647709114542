import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { setUserPermissions } from './user';

export const DEMOTE_WRITER_REQUEST = 'user/DEMOTE_WRITER_REQUEST';
export const DEMOTE_WRITER_SUCCESS = 'user/DEMOTE_WRITER_SUCCESS';
export const DEMOTE_WRITER_FAILURE = 'user/DEMOTE_WRITER_FAILURE';

export const demoteWriterRequest = createAction(DEMOTE_WRITER_REQUEST);
export const demoteWriterSuccess = createAction(DEMOTE_WRITER_SUCCESS);
export const demoteWriterFailure = createAction(DEMOTE_WRITER_FAILURE);

export const demoteWriter = userId => (dispatch, getState, { request }) => {
  dispatch(demoteWriterRequest());
  const promise = request.delete(`/_api/users/${userId}/permissions`);
  return promise
    .then(user => {
      dispatch(demoteWriterSuccess(user));
      dispatch(setUserPermissions(user));
    })
    .catch(() => dispatch(demoteWriterFailure()))
    .then(() => promise);
};
