import {
  PUB_SUB_CHANNEL_APP,
  PUB_SUB_CHANNEL_MODAL,
} from '@wix/communities-blog-client-common/dist/src/constants/pub-sub-channels';

import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { isPublished } from '@wix/communities-blog-universal/dist/src/post-utils';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';
import { normalizePost } from '../services/post-utils';
import { getCategoryIds } from '../selectors/categories-selectors';
import { getPost } from '../selectors/post-selectors';
import { debug } from '../services/debug';
import { setIsLoading } from '../store/is-loading/is-loading-actions';
import { navigateWithinPostPage } from './navigate-within-blog';

export const PUBLISH_POST_REQUEST = 'post/PUBLISH_REQUEST';
export const PUBLISH_POST_SUCCESS = 'post/PUBLISH_SUCCESS';
export const PUBLISH_POST_FAILURE = 'post/PUBLISH_FAILURE';

export const publishPostRequest = createAction(PUBLISH_POST_REQUEST);
export const publishPostSuccess = createAction(PUBLISH_POST_SUCCESS, payload => payload, (payload, meta) => meta);
export const publishPostFailure = createAction(PUBLISH_POST_FAILURE);

const setIsPublishing = isPublishing => ({
  ...setIsLoading('publish', undefined, isPublishing),
  meta: { publish: [PUB_SUB_CHANNEL_APP, PUB_SUB_CHANNEL_MODAL] },
});

export const publishPost = postId => (dispatch, getState, { request }) => {
  dispatch(publishPostRequest());
  dispatch(setIsPublishing(true));

  const state = getState();
  const isUpdated = isPublished(getPost(state, postId));
  const blogCategoryIds = getCategoryIds(state);

  const promise = request.post(`/_api/posts/${postId}/publish`);

  return promise
    .then(post => dispatch(publishPostSuccess(normalizePost({ post, blogCategoryIds }), { isUpdated })))
    .catch(error => {
      debug.error(error);
      return dispatch(publishPostFailure(error));
    })
    .then(() => {
      dispatch(setIsPublishing(false));
      return promise;
    });
};

export const publishPostAndNavigate = postId => dispatch =>
  dispatch(publishPost(postId)).then(({ slug }) => dispatch(navigateWithinPostPage(`/${slug}`)));

export const publishPostPromisified = createPromisifiedAction(publishPost, data => data, response => response.status);
