import _ from 'lodash';

export const UPDATE_USER_REQUEST = 'user/UPDATE_REQUEST';
export const UPDATE_USER_SUCCESS = 'user/UPDATE_SUCCESS';
export const UPDATE_USER_FAILURE = 'user/UPDATE_FAILURE';

export const updateUserRequest = payload => ({ type: UPDATE_USER_REQUEST, payload });
export const updateUserSuccess = payload => ({ type: UPDATE_USER_SUCCESS, payload });
export const updateUserFailure = () => ({ type: UPDATE_USER_FAILURE });

export const updateUser = (userId, data) => (dispatch, getState, { request }) => {
  dispatch(updateUserRequest({ _id: userId, data }));

  const promise = request.patch(`/_api/users/${userId}`, _.omit(data, '_id'));
  return promise
    .then(user => dispatch(updateUserSuccess(user)))
    .catch(() => dispatch(updateUserFailure()))
    .then(() => promise);
};

export const updateUserSettings = (userId, data) => (dispatch, getState, { request }) => {
  dispatch(updateUserRequest({ _id: userId, data }));

  const promise = request.patch(`/_api/users/${userId}/settings`, _.omit(data, '_id'));
  return promise
    .then(user => dispatch(updateUserSuccess(user)))
    .catch(() => dispatch(updateUserFailure()))
    .then(() => promise);
};
