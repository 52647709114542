import { createSelector } from 'reselect';
import _ from 'lodash';

export const getUserMap = state => state.users;

const getUserSlug = (state, slug) => slug;

const getUserSiteMemberId = (state, siteMemberId) => siteMemberId;

export const getUserById = (state, userId) => state.users[userId];

const getIdList = (state, idList) => idList;

export const getUsersByIdList = createSelector(
  [getUserMap, getIdList],
  (users, idList) => _.map(idList, id => users[id]),
);

export const getUserBySlug = createSelector(
  [getUserMap, getUserSlug],
  (users, slug) => _.find(users, ['slug', slug]),
);

export const getUserBySiteMemberId = createSelector(
  [getUserMap, getUserSiteMemberId],
  (users, siteMemberId) => _.find(users, ['siteMemberId', siteMemberId]),
);
