export const NEW_NOTIFICATION = 'user/NEW_NOTIFICATION';
export const newNotification = payload => ({
  type: NEW_NOTIFICATION,
  payload,
});

export const USER_SET_PERMISSIONS = 'user/SET_PERMISSIONS';
export const setUserPermissions = payload => ({
  type: USER_SET_PERMISSIONS,
  payload,
});
